import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './components/App'
import Dummy from './components/Dummy'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/cpa/dummy" element={<Dummy />} />
      <Route path="*" element={<App />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
)

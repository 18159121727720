import React from 'react'
import Promotion from './Promotion'
import styles from './App.module.scss'

const App = () => {
  const showClose = navigator.userAgent.includes('chai')
  return (
    <>
      {showClose && (
        <a href="chaiinternalweb://close" className={styles.close}>
          &times;
        </a>
      )}

      <Promotion />
    </>
  )
}

export default App

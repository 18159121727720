import React from 'react'

const Dummy = () => {
  return (
    <>
      <script>
        setTimeout(function()
        {window.location.replace('chaiinternalweb://close')}, 0)
      </script>
    </>
  )
}

export default Dummy

import React from 'react'
import styles from './Image.module.scss'

const Image = ({ src, href }) =>
  href ? (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <img src={src} alt="" className={styles.image} />
    </a>
  ) : (
    <img src={src} alt="" className={styles.image} />
  )

export default Image

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Image from './Image'

const BASE = 'https://static.chai.finance/exhibitions'
const FILE = 'article.json'
const getURL = pathname => filename =>
  `${BASE}${pathname.toLowerCase()}/${filename}`

const Promotion = () => {
  const { pathname } = window.location
  const getLink = getURL(pathname)

  /* state */
  const [images, setImages] = useState()
  const [error, setError] = useState()

  /* effect */
  useEffect(() => {
    const load = async () => {
      try {
        const { data } = await axios(getLink(FILE))
        setImages(data.images)
      } catch (error) {
        setError(error)
      }
    }

    pathname.length > 1 && load()
    // eslint-disable-next-line
  }, [])

  return error
    ? error.message
    : images
    ? images.map(({ src, link }, index) => (
        <Image src={getLink(src)} href={link} key={index} />
      ))
    : null
}

export default Promotion
